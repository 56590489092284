/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    function dots(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }


  $(document).ready(function() {
      $('.lang--current').on('click', function() {
        $(this).parent().toggleClass('show');
      });

      $('input[name="update_cart"]' ).prop( 'disabled', false);

      if($('body').hasClass('chooserefill') || $('body').hasClass('single-product')){
        $('.products').hide();

        $(window).scroll(function(e) {
          var scrollTop = $(window).scrollTop();
          var docHeight = $(document).height();
          var winHeight = $(window).height();
          var scrollPercent = (scrollTop) / (docHeight - winHeight);
          var scrollPercentRounded = Math.round(scrollPercent * 100);
          var done = 0;


          if(done !== 1){
            if(scrollPercentRounded > 1) {
              $('.products').slideDown();
              $('.products .discount').matchHeight();
              $('.products .mh-p').matchHeight();
              done = 1;
            }
          }

          if(scrollPercentRounded < 1) {
            $('.products').slideUp();
          }
        });
      }

      AOS.init({
        duration: 500,
        once: true
      });

      $('.mobile-menu-trigger').on('click', function() {
        $('body').toggleClass('menu-open');
        $(this).toggleClass('active');
      });

      $('.product-item .flex .persons .more').click(function(){
        var cur = parseInt($(this).parent().find('.num').text());
        $(this).parent().find('.num').text(cur + 1);
        calc_flex_product();
      });

      $('.product-item .flex .persons .less').click(function(){
        var cur = parseInt($(this).parent().find('.num').text());

        if(cur > 1){
          $(this).parent().find('.num').text(cur - 1);
          calc_flex_product();
        }
      });

      function calc_flex_product() {
        var product = parseInt($('.product-item .flex .count .num').text());
        var persons = parseInt($('.product-item .flex .persons .num').text());
        var checkout = $('.product-item .checkout-button').attr('data-checkout');

        $('.flex .product-info').hide();

        // 1x poetsen
        if(product == 1) {
          $('.product-item .flex .months').text('6');
          $('.product-item .flex .total-price').html('&euro;' + (19.95 * persons).toFixed(2));
          $('.product-item .flex .month-price').html('&euro;' + (3.32 * persons).toFixed(2));
          $('.flex .product-info.month-one').show();

          $('.product-item .checkout-button').attr('href', checkout + '?add-to-cart=134&quantity=' + persons);
        // 2x poetsen
        } else if(product == 2) {
          $('.product-item .flex .months').text('3');
          $('.product-item .flex .total-price').html('&euro;' + (19.95 * persons).toFixed(2));
          $('.product-item .flex .month-price').html('&euro;' + (6.65 * persons).toFixed(2));
          $('.flex .product-info.month-three').show();

          $('.product-item .checkout-button').attr('href', checkout + '?add-to-cart=132&quantity=' + persons);

        // 3x poetsen
        } else if(product == 3) {
          $('.product-item .flex .months').text('2');
          $('.product-item .flex .total-price').html('&euro;' + (19.95 * persons).toFixed(2));
          $('.product-item .flex .month-price').html('&euro;' + (9.97 * persons).toFixed(2));
          $('.flex .product-info.month-two').show();

          $('.product-item .checkout-button').attr('href', checkout + '?add-to-cart=133&quantity=' + persons);
        }
      }

      $('.product-item .flex .count .more').click(function(){
        var cur = parseInt($(this).parent().find('.num').text());

        if(cur < 3) {
          $(this).parent().find('.num').text(cur + 1);
          calc_flex_product();
        }
      });

      $('.product-item .flex .count .less').click(function(){
        var cur = parseInt($(this).parent().find('.num').text());

        if(cur > 1) {
          $(this).parent().find('.num').text(cur - 1);
          calc_flex_product();
        }
      });

      $('.add-button').click(function(e) {

        $('.faq-loader').fadeIn();

        setTimeout(function(){
          $('.faq-loader').fadeOut();
        }, 1400);

         e.preventDefault();
         if(!$(this).hasClass('added')) {
           var prodID = $(this).attr('data-id');
           addToCart(prodID);

           $(this).addClass('added');
           $(this).html('Verwijder <i class="fa fa-times"></i>');
         }else{
           var prodID = $(this).attr('data-id');
           removeFromCart(prodID);
           $(this).removeClass('added');
           $(this).html('Toevoegen - ' + $(this).attr('data-price').replace(".", ",") + '');
         }

         return false;
      });

      function addToCart(prodID) {
         $.get('https://chooseteethcare.com/product/chooserefill-plan/?add-to-cart=' + prodID + '&quantity=1', function() {
         // $.get('/choose/?post_type=product&add-to-cart=' + prodID + '&quantity=1', function() {
            console.log('added');
         });
      }

    function removeFromCart(prodID) {
      $.ajax({
        type: "POST",
        url: 'https://chooseteethcare.com/wp-admin/admin-ajax.php',
        data: {action : 'remove_item_from_cart','product_id' : prodID},
        success: function (res) {
          if (res) {
            console.log('removed');
          }
        }
      });
    }

      $('.product-item .impact .persons .more').click(function(){
        var cur = parseInt($(this).parent().find('.num').text());
        $(this).parent().find('.num').text(cur + 1);
        calc_impact_product();
      });

      $('.product-item .impact .persons .less').click(function(){
        var cur = parseInt($(this).parent().find('.num').text());

        if(cur > 1){
          $(this).parent().find('.num').text(cur - 1);
          calc_impact_product();
        }
      });

      function calc_impact_product() {
        var product = parseInt($('.product-item .impact .count .num').text());
        var persons = parseInt($('.product-item .impact .persons .num').text());
        var checkout = $('.product-item .checkout-button').attr('data-checkout');

        $('.impact .product-info').hide();

        // 1x poetsen
        if(product == 1) {
          $('.product-item .impact .months').text('12');
          $('.product-item .impact .total-price').html('&euro;' + (34.95 * persons).toFixed(2));
          $('.product-item .impact .month-price').html('&euro;' + (2.91 * persons).toFixed(2));
          $('.impact .product-info.month-one').show();

          $('.product-item .checkout-button').attr('href', checkout + '?add-to-cart=142&quantity=' + persons);
        // 2x poetsen
        } else if(product == 2) {
          $('.product-item .impact .months').text('12');
          $('.product-item .impact .total-price').html('&euro;' + (69.95 * persons).toFixed(2));
          $('.product-item .impact .month-price').html('&euro;' + (5.83 * persons).toFixed(2));
          $('.impact .product-info.month-three').show();

          $('.product-item .checkout-button').attr('href', checkout + '?add-to-cart=143&quantity=' + persons);

        // 3x poetsen
        } else if(product == 3) {
          $('.product-item .impact .months').text('12');
          $('.product-item .impact .total-price').html('&euro;' + (99.95 * persons).toFixed(2));
          $('.product-item .impact .month-price').html('&euro;' + (8.33 * persons).toFixed(2));
          $('.impact .product-info.month-two').show();

          $('.product-item .checkout-button').attr('href', checkout + '?add-to-cart=144&quantity=' + persons);
        }
      }

      $('.product-item .impact .count .more').click(function(){
        var cur = parseInt($(this).parent().find('.num').text());

        if(cur < 3) {
          $(this).parent().find('.num').text(cur + 1);
          calc_impact_product();
        }
      });

      $('.product-item .impact .count .less').click(function(){
        var cur = parseInt($(this).parent().find('.num').text());

        if(cur > 1) {
          $(this).parent().find('.num').text(cur - 1);
          calc_impact_product();
        }
      });

      $('.faq-single-title').click(function(){
        $(this).parent().find('.faq-single-content').slideToggle();
        $(this).parent().toggleClass('active');
      });

      $('.cat-title').click(function(){
        $(this).parent().find('.faq-wrap').slideToggle();
        $(this).parent().toggleClass('active');
      });


      $('.sub-faq .single').click(function(){
        $(this).find('.faq-content').slideToggle();
        $(this).toggleClass('active');

        if($(this).find('i').hasClass('fa-plus')){
          $(this).find('i').removeClass('fa-plus');
          $(this).find('i').addClass('fa-minus');
        }else{
          $(this).find('i').addClass('fa-plus');
          $(this).find('i').removeClass('fa-minus');
        }
      });

      $('.addcart-flex').click(function(){
        $('.cart-overlay').show();
        $('.info').hide();
        $('.info.flex').show();

        $('.first-addon').html('Flexibel Refill Plan');

        setTimeout(function(){
          $('.cart-overlay .inner').addClass('show');
          window.dispatchEvent(new Event('resize'));
        }, 100);


      });

      $('.addcart-impact').click(function(){
        $('.cart-overlay').show();
        $('.info').hide();
        $('.info.impact').show();

        $('.first-addon').html('Impact Refill Plan');

        var checkout = $('.product-item .checkout-button').attr('data-checkout');
        $('.product-item .checkout-button').attr('href', checkout + '?add-to-cart=143&quantity=1');

        setTimeout(function(){
          $('.cart-overlay .inner').addClass('show');
          window.dispatchEvent(new Event('resize'));
        }, 100);
      });

      $('.close').click(function(){
        $('.cart-overlay .inner').removeClass('show');
        window.dispatchEvent(new Event('resize'));


        setTimeout(function(){
          $('.cart-overlay').hide();
        }, 200);
      });
    });

  //
  // function seeFooter(){
  //   if ($('.footer').visible()) {
  //     $('.products').addClass('bot');
  //   } else {
  //     $('.products').removeClass('bot');
  //   }
  // }

  $.fn.isInViewport = function() {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
  };

  $(window).scroll(function(e) {


    if ($('footer').isInViewport()) {
      if ($(window).width() < 767) {
        var scrollTop = $(window).scrollTop();
        var docHeight = $(document).height();
        var winHeight = $(window).height();
        var scrollPercent = (scrollTop) / (docHeight - winHeight);
        var scrollPercentRounded = Math.round(scrollPercent * 100);
        if(scrollPercentRounded >= 98)
        {
          $('.products').addClass('bot');
        }else{
          $('.products').removeClass('bot');
        }
      }else{
        $('.products').addClass('bot');
      }
    }else{
      $('.products').removeClass('bot');
    }

    // seeFooter();
  });






  // Load Events
  //$(document).ready(UTIL.loadEvents);

  $('#searchbarinput').on('input', function(){
    var value = $(this).val();

    if(value.length >= 3){
      // If more than 3 chars, search
      $('.faq-list').hide();
      $('.faq-list-results').show();

      var request = $.ajax({
        url:  theme_url +  "/ajax/search.php",
        method: "POST",
        data: {data: value},
        dataType: "html"
      });

      $('.faq-loader').fadeIn();

      request.done(function (output) {

        $('.faq-loader').fadeOut();
        $('.searchresults').children().remove();

        if(output == 'Geen overeenkomsten'){
          // Geen posts gevonden

          alert('geen overeenkomsten');

        }else{
          // Return posts

          var returnArray = JSON.parse(output);

          Object.keys(returnArray).forEach(function(key) {
            $('.searchresults').append('<div class="faq-single-wrap"><div class="faq-single-title">'+ returnArray[key][0] +'</div><div class="faq-single-content">'+returnArray[key][1]+'</div></div>');
          });

        }

        $('.faq-list-results .faq-single-title').click(function(){
            $(this).parent().find('.faq-single-content').slideDown();
        });


      });

      request.fail(function (jqXHR, textStatus) {
        alert("AJAX Request failed, please get in contact with your manager");
      });
    }else{
      $('.faq-list').show();
      $('.faq-list-results').hide();
      $('.searchresults').children().remove();
    }
  });

  $('.colorcount').each(function () {

    if($(this).text().includes("%")){
      var percentage = true;
    }else{
      var percentage = false;
    }

    $(this).prop('Counter',0).animate({
      Counter: $(this).text()
    }, {
      duration: 4000,
      easing: 'swing',
      step: function (now) {

        if(percentage){
          $(this).text(dots(Math.ceil(now)) + '%');
        }else{
          $(this).text(dots(Math.ceil(now)));
        }


      }
    });
  });

  function doMatchHeight(){
    $('.products .discount').matchHeight();
    $('.products .mh-p').matchHeight();
    $('.addons h5').matchHeight();
    $('.thankyou-items .inner-product').matchHeight();
    $('.thankyou-mh').matchHeight();
  }

  setTimeout(function(){
        doMatchHeight();
  }, 600);

  setTimeout(function(){
    doMatchHeight();
  }, 1500);


  $( window ).resize(function() {
    doMatchHeight();
  });

  if ($(window).width() < 960) {

    // $('.list-advantages').slick({
    //   dots: false,
    //   arrows: false,
    //   infinite: true,
    //   speed: 300,
    //   slidesToShow: 3,
    //   slidesToScroll: 3,
    //   responsive: [
    //     {
    //       breakpoint: 1024,
    //       settings: {
    //         slidesToShow: 3,
    //         slidesToScroll: 3,
    //         autoplay: true,
    //         autoplaySpeed: 2000,
    //         infinite: true,
    //       }
    //     },
    //     {
    //       breakpoint: 767,
    //       settings: {
    //         slidesToShow: 3,
    //         slidesToScroll: 3
    //       }
    //     },
    //     {
    //       breakpoint: 550,
    //       settings: {
    //         slidesToShow: 2,
    //         slidesToScroll: 2
    //       }
    //     }
    //   ]
    // });
  }

  $('.product_image.slider').slick({
    dots: false,
    arrows: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
  });

  $('.product_image_second').click(function(){
    const nr = $(this).attr('data-product-img');

    $('.product_image.slider').slick('slickGoTo', nr);
  });


  $('.known-wrapper-logos').slick({
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        centerMode: true,
        centerPadding: '0px',
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
})

  var running = false;


  function startCounter() {
    running = true;
    $('.billion').each(function () {
      $(this).prop('Counter',0).animate({
        Counter: $(this).text()
      }, {
        duration: 4000,
        easing: 'swing',
        step: function (now) {
          $(this).text(dots(Math.ceil(now)));
        }
      });
    });

    setTimeout(function(){
      $('.billion').text('1.5 miljard');
    }, 4050);
  }

  $(window).scroll(function() {

    if($('.billion').length){
      var top_of_element = $(".billion").offset().top;
      var bottom_of_element = $(".billion").offset().top + $(".billion").outerHeight();
      var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
      var top_of_screen = $(window).scrollTop();
      if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){
        running ? '' : startCounter();
      }
    }


  });

})(jQuery); // Fully reference jQuery after this point.
